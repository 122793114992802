"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var statsig_js_1 = require("statsig-js");
var StatsigStatic_1 = require("./StatsigStatic");
var SDKVersion_1 = require("./SDKVersion");
var Statsig = /** @class */ (function () {
    // Exposed for RN sdks to override this class - an instance of this class
    // is undefined
    function Statsig(sdkKey, user, options) {
        if (Statsig_1.instance != null && Statsig_1.canThrow()) {
            throw new Error('Cannot create another instance of the static Statsig class');
        }
        Statsig_1.instance = new statsig_js_1.StatsigClient(sdkKey, user, options);
    }
    Statsig_1 = Statsig;
    Statsig.initialize = function (sdkKey, user, options) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!Statsig_1.instance) {
                            Statsig_1.instance = new statsig_js_1.StatsigClient(sdkKey, user, options);
                            Statsig_1.instance.setAppState(this.appState);
                            Statsig_1.instance.setSDKPackageInfo(this.sdkPackageInfo);
                            Statsig_1.instance.setNativeModules(this.nativeModules);
                            Statsig_1.instance.setPlatform(this.platform);
                            Statsig_1.instance.setRNDeviceInfo(this.deviceInfo);
                            Statsig_1.instance.setExpoConstants(this.expoConstants);
                            Statsig_1.instance.setExpoDevice(this.expoDevice);
                            Statsig_1.instance.setOnCacheLoadedReactCallback(this.onCacheLoadedCallback);
                        }
                        return [4 /*yield*/, Statsig_1.instance.initializeAsync()];
                    case 1:
                        _a.sent();
                        Statsig_1.processEventQueue();
                        return [2 /*return*/];
                    case 2:
                        e_1 = _a.sent();
                        if (Statsig_1.canThrow()) {
                            throw e_1;
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    Statsig.bootstrap = function (sdkKey, initializeValues, user, options) {
        if (Statsig_1.instance != null) {
            Statsig_1.setInitializeValues(initializeValues);
            return;
        }
        if (options == null) {
            options = {};
        }
        options.initializeValues = initializeValues;
        Statsig_1.instance = new statsig_js_1.StatsigClient(sdkKey, user, options);
        Statsig_1.instance.setSDKPackageInfo({
            sdkType: 'react-client',
            sdkVersion: SDKVersion_1.version,
        });
    };
    Statsig.flushEvents = function () {
        return this.capture(function () { return Statsig_1.getClientX().flushEvents(); }, undefined);
    };
    Statsig.reenableAllLogging = function () {
        return this.capture(function () { return Statsig_1.getClientX().reenableAllLogging(); }, undefined);
    };
    Statsig.prefetchUsers = function (users) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.capture(function () { return Statsig_1.getClientX().prefetchUsers(users); }, Promise.resolve())];
            });
        });
    };
    Statsig.setInitializeValues = function (initializeValues) {
        this.capture(function () { return Statsig_1.getClientX().setInitializeValues(initializeValues); }, undefined);
    };
    Statsig.getCurrentUser = function () {
        return this.capture(function () { return Statsig_1.getClientX().getCurrentUser(); }, null);
    };
    Statsig.checkGate = function (gateName, ignoreOverrides) {
        if (ignoreOverrides === void 0) { ignoreOverrides = false; }
        return this.capture(function () { return Statsig_1.getClientX().checkGate(gateName, ignoreOverrides); }, false);
    };
    Statsig.getFeatureGate = function (gateName, options) {
        return this.capture(function () { return Statsig_1.getClientX().getFeatureGate(gateName, options); }, new statsig_js_1.FeatureGate(gateName, false, '', {
            time: Date.now(),
            reason: statsig_js_1.EvaluationReason.Uninitialized,
        }));
    };
    Statsig.checkGateWithExposureLoggingDisabled = function (gateName, options) {
        return this.capture(function () {
            return Statsig_1.getClientX().checkGateWithExposureLoggingDisabled(gateName, options === null || options === void 0 ? void 0 : options.ignoreOverrides);
        }, false);
    };
    Statsig.manuallyLogGateExposure = function (gateName) {
        this.capture(function () { return Statsig_1.getClientX().logGateExposure(gateName); }, undefined);
    };
    Statsig.getConfig = function (configName, ignoreOverrides) {
        if (ignoreOverrides === void 0) { ignoreOverrides = false; }
        return this.capture(function () { return Statsig_1.getClientX().getConfig(configName, ignoreOverrides); }, new statsig_js_1.DynamicConfig(configName, {}, '', {
            time: Date.now(),
            reason: statsig_js_1.EvaluationReason.Uninitialized,
        }));
    };
    Statsig.getConfigWithExposureLoggingDisabled = function (configName, options) {
        return this.capture(function () {
            return Statsig_1.getClientX().getConfigWithExposureLoggingDisabled(configName, options === null || options === void 0 ? void 0 : options.ignoreOverrides);
        }, new statsig_js_1.DynamicConfig(configName, {}, '', {
            time: Date.now(),
            reason: statsig_js_1.EvaluationReason.Uninitialized,
        }));
    };
    Statsig.manuallyLogConfigExposure = function (configName) {
        this.capture(function () { return Statsig_1.getClientX().logConfigExposure(configName); }, undefined);
    };
    Statsig.getExperiment = function (experimentName, keepDeviceValue, ignoreOverrides) {
        if (keepDeviceValue === void 0) { keepDeviceValue = false; }
        if (ignoreOverrides === void 0) { ignoreOverrides = false; }
        return this.capture(function () {
            return Statsig_1.getClientX().getExperiment(experimentName, keepDeviceValue, ignoreOverrides);
        }, new statsig_js_1.DynamicConfig(experimentName, {}, '', {
            time: Date.now(),
            reason: statsig_js_1.EvaluationReason.Uninitialized,
        }));
    };
    Statsig.getExperimentWithExposureLoggingDisabled = function (experimentName, options) {
        return this.capture(function () {
            return Statsig_1.getClientX().getExperimentWithExposureLoggingDisabled(experimentName, options === null || options === void 0 ? void 0 : options.keepDeviceValue, options === null || options === void 0 ? void 0 : options.ignoreOverrides);
        }, new statsig_js_1.DynamicConfig(experimentName, {}, '', {
            time: Date.now(),
            reason: statsig_js_1.EvaluationReason.Uninitialized,
        }));
    };
    Statsig.manuallyLogExperimentExposure = function (experimentName, keepDeviceValue) {
        this.capture(function () {
            return Statsig_1.getClientX().logExperimentExposure(experimentName, keepDeviceValue);
        }, undefined);
    };
    Statsig.getLayer = function (layerName, keepDeviceValue) {
        if (keepDeviceValue === void 0) { keepDeviceValue = false; }
        return this.capture(function () { return Statsig_1.getClientX().getLayer(layerName, keepDeviceValue); }, statsig_js_1.Layer._create(layerName, {}, '', {
            time: Date.now(),
            reason: statsig_js_1.EvaluationReason.Uninitialized,
        }));
    };
    Statsig.getLayerWithExposureLoggingDisabled = function (layerName, options) {
        return this.capture(function () {
            return Statsig_1.getClientX().getLayerWithExposureLoggingDisabled(layerName, options === null || options === void 0 ? void 0 : options.keepDeviceValue);
        }, statsig_js_1.Layer._create(layerName, {}, '', {
            time: Date.now(),
            reason: statsig_js_1.EvaluationReason.Uninitialized,
        }));
    };
    Statsig.manuallyLogLayerParameterExposure = function (layerName, parameterName, keepDeviceValue) {
        if (keepDeviceValue === void 0) { keepDeviceValue = false; }
        this.capture(function () {
            return Statsig_1.getClientX().logLayerParameterExposure(layerName, parameterName, keepDeviceValue);
        }, undefined);
    };
    Statsig.logEvent = function (eventName, value, metadata) {
        var _this = this;
        if (value === void 0) { value = null; }
        if (metadata === void 0) { metadata = null; }
        this.capture(function () {
            if (!Statsig_1.instance || !Statsig_1.initializeCalled()) {
                if (Statsig_1.eventQueue.length >= _this.MAX_QUEUED_EVENTS) {
                    return;
                }
                Statsig_1.eventQueue.push({ eventName: eventName, value: value, metadata: metadata });
                return;
            }
            Statsig_1.instance.logEvent(eventName, value, metadata);
        }, undefined);
    };
    Statsig.updateUser = function (user) {
        return this.capture(function () { return Statsig_1.getClientX().updateUser(user); }, Promise.resolve(false));
    };
    Statsig.updateUserWithValues = function (user, values) {
        return this.capture(function () { return Statsig_1.getClientX().updateUserWithValues(user, values); }, false);
    };
    Statsig.shutdown = function () {
        this.capture(function () { return Statsig_1.getClientX().shutdown(); }, undefined);
        Statsig_1.instance = undefined;
    };
    /**
     * Overrides the given gate locally with the given value
     * @param gateName - name of the gate to override
     * @param value - value to assign to the gate
     */
    Statsig.overrideGate = function (gateName, value) {
        this.capture(function () {
            var _a;
            if (((_a = Statsig_1.getAllOverrides()['gates']) === null || _a === void 0 ? void 0 : _a[gateName]) === value) {
                return;
            }
            Statsig_1.getClientX().overrideGate(gateName, value);
            Statsig_1.updateContext();
        }, undefined);
    };
    /**
     * Overrides the given config locally with the given value
     * @param configName - name of the config to override
     * @param value - value to assign to the config
     */
    Statsig.overrideConfig = function (configName, value) {
        this.capture(function () {
            var _a;
            if (((_a = Statsig_1.getAllOverrides()['configs']) === null || _a === void 0 ? void 0 : _a[configName]) === value) {
                return;
            }
            Statsig_1.getClientX().overrideConfig(configName, value);
            Statsig_1.updateContext();
        }, undefined);
    };
    /**
     * Overrides the given layer locally with the given value
     * @param layerName - name of the layer to override
     * @param value - value to assign to the layer
     */
    Statsig.overrideLayer = function (layerName, value) {
        this.capture(function () {
            var _a;
            if (((_a = Statsig_1.getAllOverrides()['layers']) === null || _a === void 0 ? void 0 : _a[layerName]) === value) {
                return;
            }
            Statsig_1.getClientX().overrideLayer(layerName, value);
            Statsig_1.updateContext();
        }, undefined);
    };
    /**
     * @param name the gate override to remove
     */
    Statsig.removeGateOverride = function (name) {
        this.capture(function () {
            Statsig_1.getClientX().removeGateOverride(name);
            Statsig_1.updateContext();
        }, undefined);
    };
    /**
     * @param name the config override to remove
     */
    Statsig.removeConfigOverride = function (name) {
        this.capture(function () {
            Statsig_1.getClientX().removeConfigOverride(name);
            Statsig_1.updateContext();
        }, undefined);
    };
    /**
     * @param name the config override to remove
     */
    Statsig.removeLayerOverride = function (name) {
        this.capture(function () {
            Statsig_1.getClientX().removeLayerOverride(name);
            Statsig_1.updateContext();
        }, undefined);
    };
    /**
     * @returns The local gate and config overrides
     */
    Statsig.getAllOverrides = function () {
        return this.capture(function () { return Statsig_1.getClientX().getAllOverrides(); }, {
            gates: {},
            configs: {},
            layers: {},
        });
    };
    Statsig.getEvaluationDetails = function () {
        var _a, _b;
        return ((_b = (_a = Statsig_1.instance) === null || _a === void 0 ? void 0 : _a.getEvaluationDetails()) !== null && _b !== void 0 ? _b : {
            reason: statsig_js_1.EvaluationReason.Uninitialized,
            time: 0,
        });
    };
    /**
     * @returns The Statsig stable ID used for device level experiments
     */
    Statsig.getStableID = function () {
        return this.capture(function () { return Statsig_1.getClientX().getStableID(); }, '');
    };
    Statsig.initializeCalled = function () {
        return Statsig_1.instance != null && Statsig_1.instance.initializeCalled();
    };
    // All methods below are for the statsig react native SDK internal usage only!
    Statsig.setAppState = function (appState) {
        if (appState != null) {
            Statsig_1.appState = appState;
        }
    };
    Statsig.setSDKPackageInfo = function (sdkPackageInfo) {
        Statsig_1.sdkPackageInfo = sdkPackageInfo;
    };
    Statsig.setReactNativeUUID = function (uuid) {
        if (uuid != null) {
            statsig_js_1.StatsigClient.setReactNativeUUID(uuid);
        }
    };
    Statsig.setAsyncStorage = function (asyncStorage) {
        if (asyncStorage != null) {
            statsig_js_1.StatsigAsyncStorage.asyncStorage = asyncStorage;
        }
    };
    Statsig.setNativeModules = function (nativeModules) {
        if (nativeModules != null) {
            Statsig_1.nativeModules = nativeModules;
        }
    };
    Statsig.setPlatform = function (platform) {
        if (platform != null) {
            Statsig_1.platform = platform;
        }
    };
    Statsig.setRNDeviceInfo = function (deviceInfo) {
        if (deviceInfo != null) {
            Statsig_1.deviceInfo = deviceInfo;
        }
    };
    Statsig.setExpoConstants = function (expoConstants) {
        if (expoConstants != null) {
            Statsig_1.expoConstants = expoConstants;
        }
    };
    Statsig.setExpoDevice = function (expoDevice) {
        if (expoDevice != null) {
            Statsig_1.expoDevice = expoDevice;
        }
    };
    Statsig.setReactContextUpdater = function (fn) {
        Statsig_1.reactContextUpdater = fn;
    };
    Statsig.setOnCacheLoadedCallback = function (fn) {
        Statsig_1.onCacheLoadedCallback = fn;
    };
    Statsig.processEventQueue = function () {
        var _a;
        for (var _i = 0, _b = Statsig_1.eventQueue; _i < _b.length; _i++) {
            var event_1 = _b[_i];
            (_a = Statsig_1.instance) === null || _a === void 0 ? void 0 : _a.logEvent(event_1.eventName, event_1.value, event_1.metadata);
        }
        Statsig_1.eventQueue = [];
    };
    Statsig.getClientX = function () {
        if (!Statsig_1.instance) {
            throw new Error('Call and wait for initialize() to finish first.');
        }
        return Statsig_1.instance;
    };
    Statsig.capture = function (task, recover) {
        try {
            return task();
        }
        catch (e) {
            if (Statsig_1.canThrow()) {
                throw e;
            }
            return recover;
        }
    };
    Statsig.updateContext = function () {
        if (Statsig_1.reactContextUpdater != null) {
            Statsig_1.reactContextUpdater();
        }
    };
    Statsig.canThrow = function () {
        var _a;
        return (typeof process === 'undefined' ||
            typeof process.env === 'undefined' ||
            ((_a = process === null || process === void 0 ? void 0 : process.env) === null || _a === void 0 ? void 0 : _a.REACT_APP_STATSIG_SDK_MODE) !== 'silent');
    };
    var Statsig_1;
    Statsig.MAX_QUEUED_EVENTS = 20;
    Statsig.eventQueue = [];
    Statsig.reactContextUpdater = null;
    Statsig = Statsig_1 = __decorate([
        (0, StatsigStatic_1.staticImplements)()
    ], Statsig);
    return Statsig;
}());
exports.default = Statsig;
