"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigHooks_1 = require("./StatsigHooks");
/**
 * A synchronous hook to check the value of an experiment.  To ensure correctness, wait for SDK initialization before
 * calling.
 * @param experimentName - the name of the experiment to check
 * @param keepDeviceValue - whether the value returned should be kept for the user on the device for the duration of the experiment
 * @param ignoreOverrides - flag to ignore overrides
 * @returns a ConfigResult indicating the DynamicConfig backing the experiment, and the loading state of the SDK
 */
function default_1(experimentName, keepDeviceValue, ignoreOverrides) {
    if (keepDeviceValue === void 0) { keepDeviceValue = false; }
    return (0, StatsigHooks_1.useExperimentImpl)(experimentName, keepDeviceValue, ignoreOverrides !== null && ignoreOverrides !== void 0 ? ignoreOverrides : false);
}
exports.default = default_1;
