"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigHooks_1 = require("./StatsigHooks");
/**
 * A synchronous hook to check the value of the gate without exposure logging.
 * To ensure correctness, wait for SDK initialization before calling.
 * @param gateName - the name of the gate to check
 * @param options - Custom options for the API
 *
 * ignoreOverrides - flag to ignore overrides
 *
 * @returns a result indicating the boolean value of the gate and loading state of the SDK
 */
function default_1(gateName, options) {
    return (0, StatsigHooks_1.useGateWithExposureLoggingDisabledImpl)(gateName, options);
}
exports.default = default_1;
