"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StatsigHooks_1 = require("./StatsigHooks");
/**
 * A synchronous hook to check the value and metadata of a FeatureGate.  To ensure correctness, wait for SDK initialization before
 * calling.
 * @param gateName - the name of the FeatureGate to check
 * @param GetFeatureGateOptions - options for this check (disable exposure logging, disable overrides, etc)
 * @returns a FeatureGateResult indicating the FeatureGate metadata and value, and the loading state of the SDK
 */
function default_1(gateName, options) {
    return (0, StatsigHooks_1.useFeatureGateImpl)(gateName, options);
}
exports.default = default_1;
